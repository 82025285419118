import _ from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';
import { DELETE, GET, PATCH, POST } from 'src/Service/ServiceBase';
import ServiceURL from 'src/Service/ServiceURL';
import TypeActions from '../TypeActions';

export function* getRequire(data) {
  const url = ServiceURL.Require + '?' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_REQUIRE_FAILED,
        error: res.message
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.GET_REQUIRE_SUCCESS,
        data: res.data
      });
      callback && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_REQUIRE_FAILED,
      error: error.response.data.message
    });
    callback && callback.failed(error.response.data.message);
  }
}

export function* createRequire(data) {
  const url = ServiceURL.Require;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.CREATE_REQUIRE_FAILED,
        error: res.message
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.CREATE_REQUIRE_SUCCESS
      });
      callback && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.CREATE_REQUIRE_FAILED,
      error: error.response.data.message
    });
    callback && callback.failed(error.response.data.message);
  }
}

export function* updateRequire(data) {
  const url = ServiceURL.Require + '/' + data.params.id;
  const callback = data.callback;
  try {
    const res = yield call(PATCH, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.UPDATE_REQUIRE_FAILED,
        error: res.message
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.UPDATE_REQUIRE_SUCCESS,
        data: res.data
      });
      callback && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.UPDATE_REQUIRE_FAILED,
      error: error.response.data.message
    });
    callback && callback.failed(error.response.data.message);
  }
}


export function* deleteRequire(data) {
  const url = ServiceURL.Require + '/' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(DELETE, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.DELETE_REQUIRE_FAILED,
        error: res.message
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.DELETE_REQUIRE_SUCCESS
      });
      callback && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.DELETE_REQUIRE_FAILED,
      error: error.response.data.message
    });
    callback && callback.failed(error.response.data.message);
  }
}


export function* getRequireById(data) {
  const url =
    ServiceURL.Require + '/' + data.params.id + '?' + data.params.populate;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_REQUIRE_BY_ID_FAILED,
        error: res.message
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.GET_REQUIRE_BY_ID_SUCCESS,
        data: res.data
      });
      callback && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_REQUIRE_BY_ID_FAILED,
      error: error.response.data.message
    });
    callback && callback.failed(error.response.data.message);
  }
}


export default function* orderSaga() {
  yield takeLatest(TypeActions.GET_REQUIRE_REQUEST, getRequire);
  yield takeLatest(TypeActions.CREATE_REQUIRE_REQUEST, createRequire);
  yield takeLatest(TypeActions.UPDATE_REQUIRE_REQUEST, updateRequire);
  yield takeLatest(TypeActions.DELETE_REQUIRE_REQUEST, deleteRequire);
  yield takeLatest(TypeActions.GET_REQUIRE_BY_ID_REQUEST, getRequireById);
}
