import TypeActions from '../TypeActions';

export const getDebt = (params, callback) => {
  return {
    type: TypeActions.GET_DEBT_REQUEST,
    params,
    callback
  };
};

export const exportForOrderDebt = (body, callback) => {
  return {
    type: TypeActions.EXPORT_FOR_ORDER_DEBT_REQUEST,
    body,
    callback
  };
};

export const exportDebt = (body, callback) => {
  return {
    type: TypeActions.EXPORT_DEBT_REQUEST,
    body,
    callback
  };
};

export const updateDebt = (params, body, callback) => {
  return {
    type: TypeActions.UPDATE_DEBT_REQUEST,
    params,
    body,
    callback
  };
};
