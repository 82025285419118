import { all, fork } from 'redux-saga/effects';
import userSaga from './userSaga';
import roleSaga from './roleSaga';
import customerSaga from './customerSaga';
import debtSaga from './debtSaga';
import exportedSaga from './exportedSaga';
import orderSaga from './orderSaga';
import requireSaga from './requireSaga';

export function* rootSagas() {
  yield all([
    fork(userSaga),
    fork(roleSaga),
    fork(customerSaga),
    fork(debtSaga),
    fork(exportedSaga),
    fork(orderSaga),
    fork(requireSaga),
  ]);
}
export default rootSagas;
