import { colors, Grid, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'fixed',
    bottom: 0,
    background: colors.grey[100],
    width: '100%',
    height: '30px',
    color: 'black'
  },
  footer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '15px',
    fontFamily: 'roboto',
    fontWeight: "500",
    fontSize: "14px"
  }
}));

function Footer(props) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.footer}>
        <div>
          Provided by{' '}
          <a href="https://bfd.vn/" target="_blank">
            BFD
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
