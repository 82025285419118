import TypeActions from '../TypeActions';

export const getOrder = (params, callback) => {
  return {
    type: TypeActions.GET_ORDER_REQUEST,
    params,
    callback
  };
};

export const createOrder = (body, callback) => {
  return {
    type: TypeActions.CREATE_ORDER_REQUEST,
    body,
    callback
  };
};

export const updateOrder = (params, body, callback) => {
  return {
    type: TypeActions.UPDATE_ORDER_REQUEST,
    params,
    body,
    callback
  };
};

export const deleteOrder = (params, callback) => {
  return {
    type: TypeActions.DELETE_ORDER_REQUEST,
    params,
    callback
  };
};

export const getOrderById = (params, callback) => {
  return {
    type: TypeActions.GET_ORDER_BY_ID_REQUEST,
    params,
    callback
  };
};

export const printOrder = (body, callback) => {
  return {
    type: TypeActions.PRINT_ORDER_REQUEST,
    body,
    callback
  };
};