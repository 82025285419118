/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthLayout from './layouts/Auth';
import DashboardLayout from './layouts/Dashboard';
import ErrorLayout from './layouts/Error';
import DashboardAnalyticsView from './views/DashboardAnalytics';
import DashboardDefaultView from './views/DashboardDefault';
import OverviewView from './views/Overview';
import PresentationView from './views/Presentation';

export default [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/import-export-management" />
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('src/containers/Login'))
      },
      {
        path: '/auth/forgot-password',
        exact: true,
        component: lazy(() => import('src/containers/ForgotPass'))
      },
      {
        path: '/auth/reset-password',
        exact: true,
        component: lazy(() => import('src/containers/ResetPass'))
      },
      {
        path: '/auth/register',
        exact: true,
        component: lazy(() => import('src/views/Register'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('src/views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('src/views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('src/views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    route: '*',
    component: DashboardLayout,
    routes: [
      //!New
      // {
      //   path: '/dashboard',
      //   exact: true,
      //   component: lazy(() => import('./containers/Dashboard'))
      // },
      {
        path: '/import-export-management',
        exact: true,
        component: lazy(() => import('./containers/ImportExportManagement'))
      },
      {
        path: '/import-export-management/order-detail/:orderId',
        exact: true,
        component: lazy(() => import('./containers/ImportExportManagement/OrderDetail'))
      },
      {
        path: '/import-export-management/order-delivered/:orderDeliveredId',
        exact: true,
        component: lazy(() => import('./containers/ImportExportManagement/OrderDeliveredDetail'))
      },
      {
        path: '/import-export-management/exported/:exportedId',
        exact: true,
        component: lazy(() => import('./containers/ImportExportManagement/ExportedDetail'))
      },
      {
        path: '/import-export-management/:importId',
        exact: true,
        component: lazy(() => import('./containers/ImportExportManagement/ImportForm'))
      },
      {
        path: '/debt-management',
        exact: true,
        component: lazy(() => import('./containers/DebtManagement'))
      },
      {
        path: '/customer-management',
        exact: true,
        component: lazy(() => import('./containers/CustomerManagement'))
      },
      {
        path: '/customer-management/view-detail/:customerId',
        exact: true,
        component: lazy(() => import('./containers/CustomerManagement/CustomerDetail'))
      },
      {
        path: '/customer-management/:customerId',
        exact: true,
        component: lazy(() => import('./containers/CustomerManagement/AddCustomer'))
      },
      {
        path: '/customer-management',
        exact: true,
        component: lazy(() => import('./containers/CustomerManagement'))
      },
      {
        path: '/account-management',
        exact: true,
        component: lazy(() => import('./containers/UserManage'))
      },
      {
        path: '/account-management/:accountId',
        exact: true,
        component: lazy(() => import('./containers/UserManage/AddUser'))
      },
      {
        path: '/require-list',
        exact: true,
        component: lazy(() => import('./containers/RequireList'))
      },
      {
        path: '/setting/user/:idUser/change-password',
        exact: true,
        component: lazy(() => import('./containers/ChangePassword'))
      },
      {
        path: '/setting/user-infor/:idUser/:tab',
        exact: true,
        component: lazy(() => import('./containers/UserSetting'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  }
];
