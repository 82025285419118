import { LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { Suspense, useEffect, useState } from 'react';
import 'react-notifications-component/dist/theme.css';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router';
import { renderRoutes } from 'react-router-config';
import { userActions } from 'src/Redux/Actions';
import queryString from 'query-string';
import _ from 'lodash';
import NavBar from './NavBar';
import TopBar from './TopBar';
import Footer from '../Footer';

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    '@media all and (-ms-high-contrast:none)': {
      height: 0 // IE11 fix
    }
  },
  content: {
    paddingTop: 64,
    flexGrow: 1,
    maxWidth: '100%',
    overflowX: 'hidden',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 56
    }
  }
}));

function Dashboard({ route }) {
  //!Const
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { userInfor } = useSelector(state => state.userReducer);
  
  //!State
  const [dataUser, setdataUser] = useState({});
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
  //!UseEffect
  useEffect(() => {
    
    dispatch(
      userActions.getUsersInfor({
        id: localStorage.getItem('id'),
        populate: queryString.stringify({ populate: 'roleId' })
      })
    );
  }, [window.location.href]);
  useEffect(() => {
    if (!!userInfor && userInfor.isPasswordChange === false) {
      history.push(
        `/setting/user/${localStorage.getItem('id')}/change-password`
      );
    }
  }, [window.location.href, userInfor]);
  if (
    !localStorage.getItem('token') ||
    localStorage.getItem('token') === '' ||
    !localStorage.getItem('expiresAt') ||
    localStorage.getItem('expiresAt') === '' ||
    !localStorage.getItem('refreshtoken') ||
    localStorage.getItem('refreshtoken') === '' ||
    !localStorage.getItem('id') ||
    localStorage.getItem('id') === '' ||
    !localStorage.getItem('roleId') ||
    localStorage.getItem('roleId') === '' ||
    (!!userInfor.status && userInfor.status !== 'active')
  ) {
    return <Redirect to="/auth/login" />;
  }
  return (
    <>
      <TopBar onOpenNavBarMobile={() => setOpenNavBarMobile(true)} />
      <NavBar
        onMobileClose={() => setOpenNavBarMobile(false)}
        openMobile={openNavBarMobile}
        dataUser={userInfor}
      />
      <div className={classes.container}>
        <div className={classes.content}>
          <Suspense fallback={<LinearProgress />}>
            {renderRoutes(route.routes)}
          </Suspense>
        </div>
      </div>
      <Footer />
    </>
  );
}

Dashboard.propTypes = {
  route: PropTypes.object
};

export default Dashboard;
