import TypeActions from '../TypeActions';

export const getRequire = (params, callback) => {
  return {
    type: TypeActions.GET_REQUIRE_REQUEST,
    params,
    callback
  };
};

export const createRequire = (body, callback) => {
  return {
    type: TypeActions.CREATE_REQUIRE_REQUEST,
    body,
    callback
  };
};

export const updateRequire = (params, body, callback) => {
  return {
    type: TypeActions.UPDATE_REQUIRE_REQUEST,
    params,
    body,
    callback
  };
};

export const deleteRequire = (params, callback) => {
  return {
    type: TypeActions.DELETE_REQUIRE_REQUEST,
    params,
    callback
  };
};

export const getRequireById = (params, callback) => {
  return {
    type: TypeActions.GET_REQUIRE_BY_ID_REQUEST,
    params,
    callback
  };
};