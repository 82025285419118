import _ from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';
import { DELETE, GET, PATCH, POST, POSTPDF } from 'src/Service/ServiceBase';
import ServiceURL from 'src/Service/ServiceURL';
import TypeActions from '../TypeActions';

export function* getDebt(data) {
  const url = ServiceURL.Debt + '?' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_DEBT_FAILED,
        error: res.message
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.GET_DEBT_SUCCESS,
        data: res.data
      });
      callback && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_DEBT_FAILED,
      error: error.response.data.message
    });
    callback && callback.failed(error.response.data.message);
  }
}

export function* updateDebt(data) {
  const url = ServiceURL.Exported + '/' + data.params.id;
  const callback = data.callback;
  try {
    const res = yield call(PATCH, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.UPDATE_DEBT_FAILED,
        error: res.message
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.UPDATE_DEBT_SUCCESS
      });
      callback && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.UPDATE_DEBT_FAILED,
      error: error.response.data.message
    });
    callback && callback.failed(error.response.data.message);
  }
}

export function* exportForOrderDebt(data) {
  const url = ServiceURL.Exported + '/export';
  const callback = data.callback;
  try {
    const res = yield call(POSTPDF, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.EXPORT_FOR_ORDER_DEBT_FAILED,
        error: res.message
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.EXPORT_FOR_ORDER_DEBT_SUCCESS
      });
      callback && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.EXPORT_FOR_ORDER_DEBT_FAILED,
      error: error.response.data.message
    });
    callback && callback.failed(error.response.data.message);
  }
}

export function* exportDebt(data) {
  const url = ServiceURL.Debt;
  const callback = data.callback;
  try {
    const res = yield call(POSTPDF, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.EXPORT_DEBT_FAILED,
        error: res.message
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.EXPORT_DEBT_SUCCESS,
        data: res.data
      });
      callback && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.EXPORT_DEBT_FAILED,
      error: error.response.data.message
    });
    callback && callback.failed(error.response.data.message);
  }
}


export default function* debtSaga() {
  yield takeLatest(TypeActions.GET_DEBT_REQUEST, getDebt);
  yield takeLatest(TypeActions.EXPORT_FOR_ORDER_DEBT_REQUEST, exportForOrderDebt);
  yield takeLatest(TypeActions.EXPORT_DEBT_REQUEST, exportDebt);
  yield takeLatest(TypeActions.UPDATE_DEBT_REQUEST, updateDebt);
}
