import { colors, Divider, Grid, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
  container: {
    // position: 'fixed',
    bottom: 0,
    left: 0,
    background: colors.grey[100],
    width: '100%',
    // height: '30px',
    color: 'black'
  },
  footerContent1: {
    fontSize: "14px",
    width: '100%',
    height: '100%',
    // display: 'flex',
    // justifyContent: 'flex-start',
    fontFamily: "roboto",
    alignItems: 'center',
    color: 'rgba(96, 125, 139, 1)',
    padding: '15px',
    '& > div': {
      marginBottom: '8px',
      '& > span': { fontSize: '14px' },
      '& > div': {
        marginBottom: '2px',
        '& > a': {
          fontSize: '14px'
        }
      }
    }
  },
  footerContent2: {
    fontFamily: "roboto",
    width: '100%',
    height: '100%',
    display: 'flex',
    color: 'rgba(96, 125, 139, 1)',
    fontWeight: 500,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    padding: '15px',
    '& > div > div': {
      marginBottom: '4px',
      fontSize: '13px',
      '& > a': {
        fontSize: '13px'
      }
    }
  },
  Divider: {
    height: '1px',
    backgroundColor: '#ccc'
  }
}));

function AuthFooter(props) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Divider className={classes.Divider} />
      {/* <div className={classes.footer}>
        <div>
          Provided by{' '}
          <a href="https://bfd.vn/" target="_blank">
            BFD
          </a>
        </div>
      </div> */}
      <Grid container>
        <Grid item lg={6} md={12} xs={12}>
          <div className={classes.footerContent1}>
            {/* <div>
              <span>
                Viện Nghiên cứu Giống và Công nghệ sinh học Lâm nghiệp
              </span>
            </div>
            <div>
              <div>
                <a>
                  Địa chỉ: 46 Đường Đức Thắng, Phường Đức Thắng, Quận Bắc Từ
                  Liêm, TP. Hà Nội
                </a>
              </div>
              <div>
                <a>Điện thoại: +84 24 38389813</a>
              </div>
            </div> */}
          </div>
        </Grid>
        <Grid item lg={6} md={12} xs={12}>
          <div className={classes.footerContent2}>
            <div>
              <div>
                Provided by{' '}
                <a href="https://bfd.vn/" target="_blank">
                  BFD
                </a>
              </div>
              {/* <div>
                <a>Copyright &copy; 2020. All Rights Reserved.</a>
              </div> */}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default AuthFooter;
