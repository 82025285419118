import TypeActions from '../TypeActions';

const initialState = {
  isGettingRequire: false,
  requires: {},
  error: '',
  isCreateRequire: false,
  isUpdateRequire: false,
  isDeleteRequire: false
};

export const requireReducer = (state = initialState, action) => {
  switch (action.type) {
    // get Require
    case TypeActions.GET_REQUIRE_REQUEST:
      return { ...state, isGettingRequire: true };
    case TypeActions.GET_REQUIRE_SUCCESS:
      return { ...state, isGettingRequire: false, requires: action.data };
    case TypeActions.GET_REQUIRE_FAILED:
      return { ...state, isGettingRequire: false, error: action.error };
    // create Require
    case TypeActions.CREATE_REQUIRE_REQUEST:
      return { ...state, isCreateRequire: true };
    case TypeActions.CREATE_REQUIRE_SUCCESS:
      return { ...state, isCreateRequire: false };
    case TypeActions.CREATE_REQUIRE_FAILED:
      return { ...state, isCreateRequire: false, error: action.error };
    // update Require
    case TypeActions.UPDATE_REQUIRE_REQUEST:
      return { ...state, isUpdateRequire: true };
    case TypeActions.UPDATE_REQUIRE_SUCCESS:
      return { ...state, isUpdateRequire: false };
    case TypeActions.UPDATE_REQUIRE_FAILED:
      return { ...state, isUpdateRequire: false, error: action.error };
    // delete Require
    case TypeActions.DELETE_REQUIRE_REQUEST:
      return { ...state, isDeleteRequire: true };
    case TypeActions.DELETE_REQUIRE_SUCCESS:
      return { ...state, isDeleteRequire: false };
    case TypeActions.DELETE_REQUIRE_FAILED:
      return { ...state, isDeleteRequire: false, error: action.error };
    case TypeActions.GET_REQUIRE_BY_ID_REQUEST:
      return { ...state, isGetOrderById: true };
    case TypeActions.GET_REQUIRE_BY_ID_SUCCESS:
      return { ...state, isGetOrderById: false, orderById: action.data };
    case TypeActions.GET_REQUIRE_BY_ID_FAILED:
      return { ...state, isGetOrderById: false, error: action.error };
    default:
      return {
        ...state
      };
  }
};

export default requireReducer;
