import _ from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';
import { DELETE, GET, PATCH, POST, POSTPDF } from 'src/Service/ServiceBase';
import ServiceURL from 'src/Service/ServiceURL';
import TypeActions from '../TypeActions';

export function* getOrder(data) {
  const url = ServiceURL.Order + '?' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_ORDER_FAILED,
        error: res.message
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.GET_ORDER_SUCCESS,
        data: res.data
      });
      callback && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_ORDER_FAILED,
      error: error.response.data.message
    });
    callback && callback.failed(error.response.data.message);
  }
}

export function* createOrder(data) {
  const url = ServiceURL.Order;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.CREATE_ORDER_FAILED,
        error: res.message
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.CREATE_ORDER_SUCCESS
      });
      callback && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.CREATE_ORDER_FAILED,
      error: error.response.data.message
    });
    callback && callback.failed(error.response.data.message);
  }
}

export function* updateOrder(data) {
  const url = ServiceURL.Order + '/' + data.params.id;
  const callback = data.callback;
  try {
    const res = yield call(PATCH, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.UPDATE_ORDER_FAILED,
        error: res.message
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.UPDATE_ORDER_SUCCESS,
        data: res.data
      });
      callback && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.UPDATE_ORDER_FAILED,
      error: error.response.data.message
    });
    callback && callback.failed(error.response.data.message);
  }
}

export function* deleteOrder(data) {
  const url = ServiceURL.Order + '/' + data.params;
  const callback = data.callback;
  try {
    const res = yield call(DELETE, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.DELETE_ORDER_FAILED,
        error: res.message
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.DELETE_ORDER_SUCCESS
      });
      callback && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.DELETE_ORDER_FAILED,
      error: error.response.data.message
    });
    callback && callback.failed(error.response.data.message);
  }
}

export function* getOrderById(data) {
  const url =
    ServiceURL.Order + '/' + data.params.id + '?' + data.params.populate;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.GET_ORDER_BY_ID_FAILED,
        error: res.message
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.GET_ORDER_BY_ID_SUCCESS,
        data: res.data
      });
      callback && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_ORDER_BY_ID_FAILED,
      error: error.response.data.message
    });
    callback && callback.failed(error.response.data.message);
  }
}

export function* printOrder(data) {
  const url = ServiceURL.Exported + '/export';
  const callback = data.callback;
  try {
    const res = yield call(POSTPDF, url, data.body);
    if (res.message && !_.isEmpty(res.message)) {
      yield put({
        type: TypeActions.PRINT_ORDER_FAILED,
        error: res.message
      });
      callback && callback.failed(res.message);
    } else {
      yield put({
        type: TypeActions.PRINT_ORDER_SUCCESS
      });
      callback && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.PRINT_ORDER_FAILED,
      error: error.response.data.message
    });
    callback && callback.failed(error.response.data.message);
  }
}

export default function* orderSaga() {
  yield takeLatest(TypeActions.GET_ORDER_REQUEST, getOrder);
  yield takeLatest(TypeActions.CREATE_ORDER_REQUEST, createOrder);
  yield takeLatest(TypeActions.GET_ORDER_BY_ID_REQUEST, getOrderById);
  yield takeLatest(TypeActions.UPDATE_ORDER_REQUEST, updateOrder);
  yield takeLatest(TypeActions.DELETE_ORDER_REQUEST, deleteOrder);
  yield takeLatest(TypeActions.PRINT_ORDER_REQUEST, printOrder);
}
