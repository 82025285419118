import { AppBar, Grid, Hidden, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { toUpper } from 'lodash-es';
import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none',
    backgroundColor: '#1C9209',
  },
  firstTextTopbar: {
    fontFamily: 'roboto',
    color: '#fff',
    fontSize: '16px',
    fontWeight: '500'
  },
  secondTextTopbar: {
    fontFamily: 'roboto',
    marginTop: '5px',
    color: '#FFFFFF',
    fontSize: '14px',
    fontWeight: '700'
  }
}));

function Topbar({ className, ...rest }) {
  const classes = useStyles();

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      // color="primary"
      style={{
        // backgroundColor: '#1C9209'
      }}
    >
      <Toolbar>
        {/* <RouterLink to="/">
          <img alt="Logo" src="/images/logos/logo--white.svg" />
        </RouterLink> */}
        <Grid container>
          {/* <Grid item lg={2} sm={11} xs={10}>
            <RouterLink
              to="/"
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <img alt="Logo" src="/images/logos/logo_iftib 1.png" />
            </RouterLink>
          </Grid> */}
          <Hidden mdDown>
            <Grid item lg={8} sm={4} xs={12}>
              <div style={{ display: 'block' }}>
                <div className={classes.firstTextTopbar}>
                  {toUpper(
                    'Công ty cổ phần Asahi Plating Việt Nam'
                  )}
                </div>
                {/* <div className={classes.secondTextTopbar}>
                  {toUpper('Hệ thống quản trị cơ sở dữ liệu giống')}
                </div> */}
              </div>
            </Grid>
          </Hidden>
          <Grid
            item
            lg={2}
            sm={1}
            xs={2}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          ></Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

Topbar.propTypes = {
  className: PropTypes.string
};

export default Topbar;
