export default {
  //!Users
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_REQUEST_SUCCESS: 'LOGIN_REQUEST_SUCCESS',
  LOGIN_REQUEST_FAILED: 'LOGIN_REQUEST_FAILED',
  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_REQUEST_SUCCESS: 'LOGOUT_REQUEST_SUCCESS',
  LOGOUT_REQUEST_FAILED: 'LOGOUT_REQUEST_FAILED',
  GET_USERS_REQUEST: 'GET_USERS_REQUEST',
  GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
  GET_USERS_FAILED: 'GET_USERS_FAILED',
  GET_USERS_INFOR_REQUEST: 'GET_USERS_INFOR_REQUEST',
  GET_USERS_INFOR_SUCCESS: 'GET_USERS_INFOR_SUCCESS',
  GET_USERS_INFOR_FAILED: 'GET_USERS_INFOR_FAILED',
  CHANGE_PASSWORD_USERS_REQUEST: 'CHANGE_PASSWORD_USERS_REQUEST',
  CHANGE_PASSWORD_USERS_SUCCESS: 'CHANGE_PASSWORD_USERS_SUCCESS',
  CHANGE_PASSWORD_USERS_FAILED: 'CHANGE_PASSWORD_USERS_FAILED',
  FORGOT_PASSWORD_USERS_REQUEST: 'FORGOT_PASSWORD_USERS_REQUEST',
  FORGOT_PASSWORD_USERS_SUCCESS: 'FORGOT_PASSWORD_USERS_SUCCESS',
  FORGOT_PASSWORD_USERS_FAILED: 'FORGOT_PASSWORD_USERS_FAILED',
  RESET_PASSWORD_USERS_REQUEST: 'RESET_PASSWORD_USERS_REQUEST',
  RESET_PASSWORD_USERS_SUCCESS: 'RESET_PASSWORD_USERS_SUCCESS',
  RESET_PASSWORD_USERS_FAILED: 'RESET_PASSWORD_USERS_FAILED',
  GET_LIST_USERS_REQUEST: 'GET_LIST_USERS_REQUEST',
  GET_LIST_USERS_SUCCESS: 'GET_LIST_USERS_SUCCESS',
  GET_LIST_USERS_FAILED: 'GET_LIST_USERS_FAILED',
  CREATE_USERS_REQUEST: 'CREATE_USERS_REQUEST',
  CREATE_USERS_SUCCESS: 'CREATE_USERS_SUCCESS',
  CREATE_USERS_FAILED: 'CREATE_USERS_FAILED',
  EDIT_USERS_REQUEST: 'EDIT_USERS_REQUEST',
  EDIT_USERS_SUCCESS: 'EDIT_USERS_SUCCESS',
  EDIT_USERS_FAILED: 'EDIT_USERS_FAILED',
  DELETE_USERS_REQUEST: 'DELETE_USERS_REQUEST',
  DELETE_USERS_SUCCESS: 'DELETE_USERS_SUCCESS',
  DELETE_USERS_FAILED: 'DELETE_USERS_FAILED',
  UPLOAD_IMAGE_REQUEST: 'UPLOAD_IMAGE_REQUEST',
  UPLOAD_IMAGE_SUCCESS: 'UPLOAD_IMAGE_SUCCESS',
  UPLOAD_IMAGE_FAILED: 'UPLOAD_IMAGE_FAILED',
  SET_PASS_USER_REQUEST: 'SET_PASS_USER_REQUEST',
  SET_PASS_USER_SUCCESS: 'SET_PASS_USER_SUCCESS',
  SET_PASS_USER_FAILED: 'SET_PASS_USER_FAILED',

  //!Role
  GET_LIST_ROLE_REQUEST: 'GET_LIST_ROLE_REQUEST',
  GET_LIST_ROLE_SUCCESS: 'GET_LIST_ROLE_SUCCESS',
  GET_LIST_ROLE_FAILED: 'GET_LIST_ROLE_FAILED',
  GET_ROLE_REQUEST: 'GET_ROLE_REQUEST',
  GET_ROLE_SUCCESS: 'GET_ROLE_SUCCESS',
  GET_ROLE_FAILED: 'GET_ROLE_FAILED',
  CREATE_ROLE_REQUEST: 'CREATE_ROLE_REQUEST',
  CREATE_ROLE_SUCCESS: 'CREATE_ROLE_SUCCESS',
  CREATE_ROLE_FAILED: 'CREATE_ROLE_FAILED',
  EDIT_ROLE_REQUEST: 'EDIT_ROLE_REQUEST',
  EDIT_ROLE_SUCCESS: 'EDIT_ROLE_SUCCESS',
  EDIT_ROLE_FAILED: 'EDIT_ROLE_FAILED',
  DELETE_ROLE_REQUEST: 'DELETE_ROLE_REQUEST',
  DELETE_ROLE_SUCCESS: 'DELETE_ROLE_SUCCESS',
  DELETE_ROLE_FAILED: 'DELETE_ROLE_FAILED',
  GET_ROLE_PERMISSION_REQUEST: 'GET_ROLE_PERMISSION_REQUEST',
  GET_ROLE_PERMISSION_SUCCESS: 'GET_ROLE_PERMISSION_SUCCESS',
  GET_ROLE_PERMISSION_FAILED: 'GET_ROLE_PERMISSION_FAILED',

  // ASAHI
  //ORDER
  GET_ORDER_REQUEST: 'GET_ORDER_REQUEST',
  GET_ORDER_SUCCESS: 'GET_ORDER_SUCCESS',
  GET_ORDER_FAILED: 'GET_ORDER_FAILED',
  CREATE_ORDER_REQUEST: 'CREATE_ORDER_REQUEST',
  CREATE_ORDER_SUCCESS: 'CREATE_ORDER_SUCCESS',
  CREATE_ORDER_FAILED: 'CREATE_ORDER_FAILED',
  UPDATE_ORDER_REQUEST: 'UPDATE_ORDER_REQUEST',
  UPDATE_ORDER_SUCCESS: 'UPDATE_ORDER_SUCCESS',
  UPDATE_ORDER_FAILED: 'UPDATE_ORDER_FAILED',
  DELETE_ORDER_REQUEST: 'DELETE_ORDER_REQUEST',
  DELETE_ORDER_SUCCESS: 'DELETE_ORDER_SUCCESS',
  DELETE_ORDER_FAILED: 'DELETE_ORDER_FAILED',
  GET_ORDER_BY_ID_REQUEST: 'GET_ORDER_BY_ID_REQUEST',
  GET_ORDER_BY_ID_SUCCESS: 'GET_ORDER_BY_ID_SUCCESS',
  GET_ORDER_BY_ID_FAILED: 'GET_ORDER_BY_ID_FAILED',
  PRINT_ORDER_REQUEST: 'PRINT_ORDER_REQUEST',
  PRINT_ORDER_SUCCESS: 'PRINT_ORDER_SUCCESS',
  PRINT_ORDER_FAILED: 'PRINT_ORDER_FAILED',
  //EXPORTED
  GET_EXPORTED_REQUEST: 'GET_EXPORTED_REQUEST',
  GET_EXPORTED_SUCCESS: 'GET_EXPORTED_SUCCESS',
  GET_EXPORTED_FAILED: 'GET_EXPORTED_FAILED',
  CREATE_EXPORTED_REQUEST: 'CREATE_EXPORTED_REQUEST',
  CREATE_EXPORTED_SUCCESS: 'CREATE_EXPORTED_SUCCESS',
  CREATE_EXPORTED_FAILED: 'CREATE_EXPORTED_FAILED',
  REFRESH_EXPORTED_REQUEST: 'REFRESH_EXPORTED_REQUEST',
  REFRESH_EXPORTED_SUCCESS: 'REFRESH_EXPORTED_SUCCESS',
  REFRESH_EXPORTED_FAILED: 'REFRESH_EXPORTED_FAILED',
  CONFIRM_EXPORTED_REQUEST: 'CONFIRM_EXPORTED_REQUEST',
  CONFIRM_EXPORTED_SUCCESS: 'CONFIRM_EXPORTED_SUCCESS',
  CONFIRM_EXPORTED_FAILED: 'CONFIRM_EXPORTED_FAILED',
  GET_EXPORTED_BY_ID_REQUEST: 'GET_EXPORTED_BY_ID_REQUEST',
  GET_EXPORTED_BY_ID_SUCCESS: 'GET_EXPORTED_BY_ID_SUCCESS',
  GET_EXPORTED_BY_ID_FAILED: 'GET_EXPORTED_BY_ID_FAILED',
  // DEBT
  GET_DEBT_REQUEST: 'GET_DEBT_REQUEST',
  GET_DEBT_SUCCESS: 'GET_DEBT_SUCCESS',
  GET_DEBT_FAILED: 'GET_DEBT_FAILED',
  UPDATE_DEBT_REQUEST: 'UPDATE_DEBT_REQUEST',
  UPDATE_DEBT_SUCCESS: 'UPDATE_DEBT_SUCCESS',
  UPDATE_DEBT_FAILED: 'UPDATE_DEBT_FAILED',
  EXPORT_FOR_ORDER_DEBT_REQUEST: 'EXPORT_FOR_ORDER_DEBT_REQUEST',
  EXPORT_FOR_ORDER_DEBT_SUCCESS: 'EXPORT_FOR_ORDER_DEBT_SUCCESS',
  EXPORT_FOR_ORDER_DEBT_FAILED: 'EXPORT_FOR_ORDER_DEBT_FAILED',
  EXPORT_DEBT_REQUEST: 'EXPORT_DEBT_REQUEST',
  EXPORT_DEBT_SUCCESS: 'EXPORT_DEBT_SUCCESS',
  EXPORT_DEBT_FAILED: 'EXPORT_DEBT_FAILED',
  
  // CUSTOMER
  GET_CUSTOMER_REQUEST: 'GET_CUSTOMER_REQUEST',
  GET_CUSTOMER_SUCCESS: 'GET_CUSTOMER_SUCCESS',
  GET_CUSTOMER_FAILED: 'GET_CUSTOMER_FAILED',
  CREATE_CUSTOMER_REQUEST: 'CREATE_CUSTOMER_REQUEST',
  CREATE_CUSTOMER_SUCCESS: 'CREATE_CUSTOMER_SUCCESS',
  CREATE_CUSTOMER_FAILED: 'CREATE_CUSTOMER_FAILED',
  UPDATE_CUSTOMER_REQUEST: 'UPDATE_CUSTOMER_REQUEST',
  UPDATE_CUSTOMER_SUCCESS: 'UPDATE_CUSTOMER_SUCCESS',
  UPDATE_CUSTOMER_FAILED: 'UPDATE_CUSTOMER_FAILED',
  DELETE_CUSTOMER_REQUEST: 'DELETE_CUSTOMER_REQUEST',
  DELETE_CUSTOMER_SUCCESS: 'DELETE_CUSTOMER_SUCCESS',
  DELETE_CUSTOMER_FAILED: 'DELETE_DEBT_FAILED',
  GET_CUSTOMER_BY_ID_REQUEST: 'GET_CUSTOMER_BY_ID_REQUEST',
  GET_CUSTOMER_BY_ID_SUCCESS: 'GET_CUSTOMER_BY_ID_SUCCESS',
  GET_CUSTOMER_BY_ID_FAILED: 'GET_CUSTOMER_BY_ID_FAILED',

  // REQUIRE
  GET_REQUIRE_REQUEST: 'GET_REQUIRE_REQUEST',
  GET_REQUIRE_SUCCESS: 'GET_REQUIRE_SUCCESS',
  GET_REQUIRE_FAILED: 'GET_REQUIRE_FAILED',
  CREATE_REQUIRE_REQUEST: 'CREATE_REQUIRE_REQUEST',
  CREATE_REQUIRE_SUCCESS: 'CREATE_REQUIRE_SUCCESS',
  CREATE_REQUIRE_FAILED: 'CREATE_REQUIRE_FAILED',
  UPDATE_REQUIRE_REQUEST: 'UPDATE_REQUIRE_REQUEST',
  UPDATE_REQUIRE_SUCCESS: 'UPDATE_REQUIRE_SUCCESS',
  UPDATE_REQUIRE_FAILED: 'UPDATE_REQUIRE_FAILED',
  DELETE_REQUIRE_REQUEST: 'DELETE_REQUIRE_REQUEST',
  DELETE_REQUIRE_SUCCESS: 'DELETE_REQUIRE_SUCCESS',
  DELETE_REQUIRE_FAILED: 'DELETE_REQUIRE_FAILED',
  GET_REQUIRE_BY_ID_REQUEST: 'GET_REQUIRE_BY_ID_REQUEST',
  GET_REQUIRE_BY_ID_SUCCESS: 'GET_REQUIRE_BY_ID_SUCCESS',
  GET_REQUIRE_BY_ID_FAILED: 'GET_REQUIRE_BY_ID_FAILED',
};
