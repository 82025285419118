import TypeActions from '../TypeActions';

export const getExported = (params, callback) => {
  return {
    type: TypeActions.GET_EXPORTED_REQUEST,
    params,
    callback
  };
};

export const createExported = (body, callback) => {
  return {
    type: TypeActions.CREATE_EXPORTED_REQUEST,
    body,
    callback
  };
};

export const refreshExported = (params, callback) => {
  return {
    type: TypeActions.REFRESH_EXPORTED_REQUEST,
    params,
    callback
  };
};

export const confirmExported = (params, callback) => {
  return {
    type: TypeActions.CONFIRM_EXPORTED_REQUEST,
    params,
    callback
  };
};

export const getExportedById = (params, callback) => {
  return {
    type: TypeActions.GET_EXPORTED_BY_ID_REQUEST,
    params,
    callback
  };
};