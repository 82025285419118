export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const BASE_URL_IMAGE = process.env.REACT_APP_BASE_URL_IMAGE;
export const BASE_URL_IMAGE_USER = process.env.REACT_APP_BASE_URL_IMAGE_USER;

export const Login = 'auth/login/';
export const Logout = 'auth/logout/';
export const User = 'users';
export const ChangePassword = 'users/change-password';
export const ForgotPassword = 'auth/forgot-password';
export const ResetPassword = 'auth/reset-password';
export const UploadImageSeedling = 'uploads/seedlingImage';
export const UploadImageUser = 'uploads/userImage';
export const Role = 'roles';
export const RolePermission = 'permission';
export const setPassUser = 'users/set-password';
export const Groups = 'groups';
export const Business = 'business';
export const Advertisement = 'advertisement';

export const Order = 'orders';
export const Customer = 'customers';
export const Debt = 'debt';
export const Exported = 'outputs';
export const Require = 'requests';

export default {
  BASE_URL,
  BASE_URL_IMAGE,
  Login,
  User,
  Logout,
  ChangePassword,
  ForgotPassword,
  ResetPassword,
  UploadImageSeedling,
  UploadImageUser,
  Role,
  RolePermission,
  setPassUser,
  Groups,
  Business,
  Advertisement,

  Order,
  Customer,
  Debt,
  Exported,
  Require,
};
