import TypeActions from '../TypeActions';

const initialState = {
  isGettingDebt: false,
  debt: {},
  error: '',
  isExportForOrderDebt: false,
  isExportDebt: false,
  isUpdateDebt: false
};

export const debtReducer = (state = initialState, action) => {
  switch (action.type) {
    // get Debt
    case TypeActions.GET_DEBT_REQUEST:
      return { ...state, isGettingDebt: true };
    case TypeActions.GET_DEBT_SUCCESS:
      return { ...state, isGettingDebt: false, debt: action.data };
    case TypeActions.GET_DEBT_FAILED:
      return { ...state, isGettingDebt: false, error: action.error };
    //
    case TypeActions.UPDATE_DEBT_REQUEST:
      return { ...state, isUpdateDebt: true };
    case TypeActions.UPDATE_DEBT_SUCCESS:
      return { ...state, isUpdateDebt: false };
    case TypeActions.UPDATE_DEBT_FAILED:
      return { ...state, isUpdateDebt: false, error: action.error };
    // create Debt
    case TypeActions.EXPORT_FOR_ORDER_DEBT_REQUEST:
      return { ...state, isExportForOrderDebt: true };
    case TypeActions.EXPORT_FOR_ORDER_DEBT_SUCCESS:
      return { ...state, isExportForOrderDebt: false };
    case TypeActions.EXPORT_FOR_ORDER_DEBT_FAILED:
      return { ...state, isExportForOrderDebt: false, error: action.error };
    // update Debt
    case TypeActions.EXPORT_DEBT_REQUEST:
      return { ...state, isExportDebt: true };
    case TypeActions.EXPORT_DEBT_SUCCESS:
      return { ...state, isExportDebt: false };
    case TypeActions.EXPORT_DEBT_FAILED:
      return { ...state, isExportDebt: false, error: action.error };

    default:
      return {
        ...state
      };
  }
};

export default debtReducer;
