import TypeActions from '../TypeActions';

export const getCustomer = (params, callback) => {
  return {
    type: TypeActions.GET_CUSTOMER_REQUEST,
    params,
    callback
  };
};

export const createCustomer = (body, callback) => {
  return {
    type: TypeActions.CREATE_CUSTOMER_REQUEST,
    body,
    callback
  };
};

export const updateCustomer = (params, body, callback) => {
  return {
    type: TypeActions.UPDATE_CUSTOMER_REQUEST,
    params,
    body,
    callback
  };
};

export const deleteCustomer = (params, callback) => {
  return {
    type: TypeActions.DELETE_CUSTOMER_REQUEST,
    params,
    callback
  };
};

export const getCustomerById = (params, callback) => {
  return {
    type: TypeActions.GET_CUSTOMER_BY_ID_REQUEST,
    params,
    callback
  };
};