import TypeActions from '../TypeActions';

const initialState = {
  isGettingExported: false,
  exporteds: {},
  error: '',
  exportedById: {},
  isGetExportedById: false,
  isRefreshExported: false,
  isConfirmExported: false,
  isCreateExported: false
};

export const exportedReducer = (state = initialState, action) => {
  switch (action.type) {
    // get Exported
    case TypeActions.GET_EXPORTED_REQUEST:
      return { ...state, isGettingExported: true };
    case TypeActions.GET_EXPORTED_SUCCESS:
      return { ...state, isGettingExported: false, exporteds: action.data };
    case TypeActions.GET_EXPORTED_FAILED:
      return { ...state, isGettingExported: false, error: action.error };
      case TypeActions.CREATE_EXPORTED_REQUEST:
      return { ...state, isCreateExported: true };
    case TypeActions.CREATE_EXPORTED_SUCCESS:
      return { ...state, isCreateExported: false };
    case TypeActions.CREATE_EXPORTED_FAILED:
      return { ...state, isCreateExported: false, error: action.error };
    // refresh Exported
    case TypeActions.REFRESH_EXPORTED_REQUEST:
      return { ...state, isRefreshExported: true };
    case TypeActions.REFRESH_EXPORTED_SUCCESS:
      return { ...state, isRefreshExported: false };
    case TypeActions.REFRESH_EXPORTED_FAILED:
      return { ...state, isRefreshExported: false, error: action.error };
    // confirm Exported
    case TypeActions.CONFIRM_EXPORTED_REQUEST:
      return { ...state, isConfirmExported: true };
    case TypeActions.CONFIRM_EXPORTED_SUCCESS:
      return { ...state, isConfirmExported: false };
    case TypeActions.CONFIRM_EXPORTED_FAILED:
      return { ...state, isConfirmExported: false, error: action.error };
    
      // get Exported by Id
    case TypeActions.GET_EXPORTED_BY_ID_REQUEST:
      return { ...state, isGetExportedById: true };
    case TypeActions.GET_EXPORTED_BY_ID_SUCCESS:
      return { ...state, isGetExportedById: false, exportedById: action.data };
    case TypeActions.GET_EXPORTED_BY_ID_FAILED:
      return { ...state, isGetExportedById: false, error: action.error };
    default:
      return {
        ...state
      };
  }
};

export default exportedReducer;
