/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react';
import { colors } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ListAlt from '@material-ui/icons/ListAlt';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import AssistantIcon from '@material-ui/icons/Assistant';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DescriptionIcon from '@material-ui/icons/Description';
import BookIcon from '@material-ui/icons/Book';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SettingsIcon from '@material-ui/icons/Settings';
import StorageIcon from '@material-ui/icons/Storage';
import HomeWorkIcon from '@material-ui/icons/HomeWork';

export default [
  {
    subheader: 'Main',
    items: [
      // {
      //   title: 'Bảng điều khiển',
      //   href: '/dashboard',
      //   role: '',
      //   icon: DashboardIcon
      // },
      {
        title: 'Quản lý xuất nhập',
        href: '/import-export-management',
        role: 'get_order',
        icon: AssignmentIcon
      },
      {
        title: 'Quản lý công nợ',
        href: '/debt-management',
        role: 'get_debt',
        icon: AttachMoneyIcon
      },
      {
        title: 'Quản lý khách hàng',
        href: '/customer-management',
        role: 'get_customer',
        icon: AccountCircleIcon,
        // items: [
        //   {
        //     title: 'Danh sách doanh nghiệp',
        //     href: '/business-database-management/list-business',
        //     role: ''
        //   },
        //   {
        //     title: 'Thêm mới',
        //     href: '/business-database-management/add-new-business',
        //     role: ''
        //   },
        //   {
        //     title: 'Thêm tài trợ',
        //     href: '/business-database-management/sponsor/add-new-sponsor-business',
        //     role: ''
        //   }
        // ]
      },
      // {
      //   title: 'Quản lý quảng cáo',
      //   href: '/advertisement-management',
      //   role: '',
      //   icon: AssistantIcon,
      //   items: [
      //     {
      //       title: 'Danh sách QC',
      //       href: '/advertisement-management/list-advertisement',
      //       role: ''
      //     },
          // {
          //   title: 'QC hết hạn',
          //   href: '/advertisement-management/advertisement-expired',
          //   role: ''
          // }
      //   ]
      // },
      {
        title: 'Quản trị tài khoản',
        href: '/account-management',
        role: 'get_user',
        icon: SettingsIcon,
        // items: [
        //   {
        //     title: 'Danh sách tài khoản',
        //     href: '/config-facility/list-account',
        //     role: ''
        //   },
        //   {
        //     title: 'Thêm mới tài khoản',
        //     href: '/config-facility/list-account/add-new-user',
        //     role: ''
        //   },
        //   {
        //     title: 'Danh sách phân quyền',
        //     href: '/config-facility/list-role',
        //     role: ''
        //   },
        //   {
        //     title: 'Thêm mới phân quyền',
        //     href: '/config-facility/list-role/add-new-role',
        //     role: ''
        //   },
        // ]
      },
      {
        title: 'Danh sách yêu cầu',
        href: '/require-list',
        role: 'get_request',
        icon: ListAlt
      },
      // {
      //   title: 'Quản lý thông báo',
      //   href: '/notifications-management',
      //   role: '',
      //   icon: NotificationsIcon
      // },
      // {
      //   title: 'Thông tin doanh nghiệp',
      //   href: '/business-info',
      //   role: '',
      //   icon: HomeWorkIcon
      // }
    ]
  }
];