import TypeActions from '../TypeActions';

const initialState = {
  isGettingOrder: false,
  orders: {},
  error: '',
  isCreateOrder: false,
  isUpdateOrder: false,
  isDeleteOrder: false,
  orderById: {},
  isGetOrderById: false,
  isPrint: false
};

export const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    // get Order
    case TypeActions.GET_ORDER_REQUEST:
      return { ...state, isGettingOrder: true };
    case TypeActions.GET_ORDER_SUCCESS:
      return { ...state, isGettingOrder: false, orders: action.data };
    case TypeActions.GET_ORDER_FAILED:
      return { ...state, isGettingOrder: false, error: action.error };
    // create Order
    case TypeActions.CREATE_ORDER_REQUEST:
      return { ...state, isCreateOrder: true };
    case TypeActions.CREATE_ORDER_SUCCESS:
      return { ...state, isCreateOrder: false };
    case TypeActions.CREATE_ORDER_FAILED:
      return { ...state, isCreateOrder: false, error: action.error };
    // update Order
    case TypeActions.UPDATE_ORDER_REQUEST:
      return { ...state, isUpdateOrder: true };
    case TypeActions.UPDATE_ORDER_SUCCESS:
      return { ...state, isUpdateOrder: false };
    case TypeActions.UPDATE_ORDER_FAILED:
      return { ...state, isUpdateOrder: false, error: action.error };
    // delete Order
    case TypeActions.DELETE_ORDER_REQUEST:
      return { ...state, isDeleteOrder: true };
    case TypeActions.DELETE_ORDER_SUCCESS:
      return { ...state, isDeleteOrder: false };
    case TypeActions.DELETE_ORDER_FAILED:
      return { ...state, isDeleteOrder: false, error: action.error };
    // get Order by Id
    case TypeActions.GET_ORDER_BY_ID_REQUEST:
      return { ...state, isGetOrderById: true };
    case TypeActions.GET_ORDER_BY_ID_SUCCESS:
      return { ...state, isGetOrderById: false, orderById: action.data };
    case TypeActions.GET_ORDER_BY_ID_FAILED:
      return { ...state, isGetOrderById: false, error: action.error };
      // print Order
    case TypeActions.PRINT_ORDER_REQUEST:
      return { ...state, isPrint: true };
    case TypeActions.PRINT_ORDER_SUCCESS:
      return { ...state, isPrint: false };
    case TypeActions.PRINT_ORDER_FAILED:
      return { ...state, isPrint: false, error: action.error };
    default:
      return {
        ...state
      };
  }
};

export default orderReducer;
