import TypeActions from '../TypeActions';

const initialState = {
  isGettingCustomer: false,
  customers: {},
  error: '',
  isCreateCustomer: false,
  isUpdateCustomer: false,
  isDeleteCustomer: false,
  customerById: {},
  isGetCustomerById: false
};

export const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    // get Customer
    case TypeActions.GET_CUSTOMER_REQUEST:
      return { ...state, isGettingCustomer: true };
    case TypeActions.GET_CUSTOMER_SUCCESS:
      return { ...state, isGettingCustomer: false, customers: action.data };
    case TypeActions.GET_CUSTOMER_FAILED:
      return { ...state, isGettingCustomer: false, error: action.error };
    // create Customer
    case TypeActions.CREATE_CUSTOMER_REQUEST:
      return { ...state, isCreateCustomer: true };
    case TypeActions.CREATE_CUSTOMER_SUCCESS:
      return { ...state, isCreateCustomer: false };
    case TypeActions.CREATE_CUSTOMER_FAILED:
      return { ...state, isCreateCustomer: false, error: action.error };
    // update Customer
    case TypeActions.UPDATE_CUSTOMER_REQUEST:
      return { ...state, isUpdateCustomer: true };
    case TypeActions.UPDATE_CUSTOMER_SUCCESS:
      return { ...state, isUpdateCustomer: false };
    case TypeActions.UPDATE_CUSTOMER_FAILED:
      return { ...state, isUpdateCustomer: false, error: action.error };
    // delete Customer
    case TypeActions.DELETE_CUSTOMER_REQUEST:
      return { ...state, isDeleteCustomer: true };
    case TypeActions.DELETE_CUSTOMER_SUCCESS:
      return { ...state, isDeleteCustomer: false };
    case TypeActions.DELETE_CUSTOMER_FAILED:
      return { ...state, isDeleteCustomer: false, error: action.error };
    // get Customer by Id
    case TypeActions.GET_CUSTOMER_BY_ID_REQUEST:
      return { ...state, isGetCustomerById: true };
    case TypeActions.GET_CUSTOMER_BY_ID_SUCCESS:
      return { ...state, isGetCustomerById: false, customerById: action.data };
    case TypeActions.GET_CUSTOMER_BY_ID_FAILED:
      return { ...state, isGetCustomerById: false, error: action.error };
    default:
      return {
        ...state
      };
  }
};

export default customerReducer;
