import { combineReducers } from 'redux';
import { userReducer } from './userReducer';
import { roleReducer } from './roleReducer';
import customerReducer from './customerReducer';
import debtReducer from './debtReducer';
import exportReducer from './exportReducer';
import orderReducer from './orderReducer';
import requireReducer from './requireReducer';


const rootReducers = combineReducers({
  userReducer,
  roleReducer,
  customerReducer,
  debtReducer,
  exportReducer,
  orderReducer,
  requireReducer,
});
export default rootReducers;
